p {
  font-size: 12px;
}

#card-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 16px;
  width: 180px;
  height: 240px;
  padding: 8px;
  box-shadow: inset;
  background-color: #fff;
  box-shadow: 2px 2px  rgba(0, 0, 0, 0.2);
}

#card-image-section {
  display: flex;
  justify-content: center;
  padding: 2px;
}

#card-image-content {
  width: 70px;
  height: 70px;
}

#card-info {
  border-top: 1px solid #d4d4d4;
  padding-top: 2px;
}

#card-item-name {
  font-size: 16px;
  font-weight: bold;
}

#card-stat-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #ededed;
}

#card-badge-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 2px;
}

#card-badge-container>span {
  margin-right: 2px;
  margin-bottom: 2px;
}
