.container {
  display: flex;
  height: 100vh;
  flex-direction: column;
  background-color: #f1f1f1;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}